.apple__sample__assessment__container {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 25% 50% 25%;
    padding-bottom: 88px;
}
.apple__sample__assessment__breadcrumb {
    grid-area: 1 / 1 / 2 / 4;
}
.apple__sample__assessment__loader {
    grid-area: 2 / 1 / 3 / 4;
}
