.apple-sample-management .content {
    height: 100%;
    display: grid;
    grid-template-columns: 0.1fr 0.9fr;
    grid-template-rows: auto auto 1fr;
    gap: 0 0.7rem;
}

.apple-sample-management .breadcrumb {
    grid-area: 1 / 1 / 2 / 3;
}

.apple-sample-management .grid-bottom-left {
    grid-area: 2 / 1 / 4 / 2;
}

.apple-sample-management .nav {
    grid-area: 2 / 2 / 3 / 3;
}

.apple-sample-management .nav-content {
    grid-area: 3 / 2 / 4 / 3;
}

.apple-sample-management .nav-item {
    margin: 0 0.7rem;
}
.apple-sample-management .nav-item:first-child {
    margin-left: 0;
}
.apple-sample-management .nav-item:last-child {
    margin-right: 0;
}

a[id^="apple-sample-management-tab"] {
    background-color: lightgrey;
    border-radius: 3px !important;
}

a[id^="apple-sample-management-tab"] .active {
    border-radius: 3px !important;
}

div[id^="apple-sample-management-tab-tabpane"] > iframe {
    border-radius: 0.2rem;
}
