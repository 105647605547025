.upload-placeholder {
    width: 500px;
    height: 450px;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swal-modal .swal-text {
    text-align: center;
}
