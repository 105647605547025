.apple__player__times {
    display: flex;
    width: 100%;
}

.apple__player__times > div {
    display: flex;
    width: 100%;
}

.apple__player__times > div > span {
    font-size: large;
    font-weight: bold;
}

.apple__player__times > div > input {
    cursor: pointer;
    width: 100%;
    margin: 0 15px;
    outline: none;
}
