.picture-crop-modal label {
    display: inline;
    cursor: pointer;
}

.picture-crop-modal .container-preview {
    width: 300px;
    height: 300px;
    background: #eee;
}

.picture-crop-modal .img-container img {
    max-width: 100%;
}

.picture-crop-modal .img-preview {
    overflow: hidden;
    width: 300px;
    max-width: 300px;
    height: 300px;
    max-height: 300px;
    padding-top: 0;
    padding-left: 0;
}
