.artist-management-biography-wysiwyg span[data-offset-key] {
    font-size: 1.2rem;
}
.artist-management-biography-to-translate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    opacity: 0.8;
    color: white;
    z-index: 99;
    padding: 0 100px;
}