.artist-search .content {
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 1fr;
    gap: 0 0.7rem;
}

.artist-search .breadcrumb {
    grid-area: 1 / 1 / 2 / 2;
}

.artist-search .search-form {
    grid-area: 2 / 1 / 3 / 2;
}

.artist-search .search-result {
    grid-area: 3 / 1 / 4 / 2;
}
