.apple__sample__assessment__ratings__rating__row > div:first-child {
    width: 115px;
}
.apple__sample__assessment__ratings__rating__row > div:last-child {
    margin-right: 27px;
}
.apple__sample__assessment__ratings__rating__delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px;
}
