/* Disable animations while waiting for this PR => https://github.com/react-bootstrap-table/react-bootstrap-table2/pull/1289/files */
.react-bootstrap-table .row-expand-slide-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
    max-height: none !important;
    transition: none !important;
}
