.plannings-tab #header-observer {
    background-color: transparent;
    min-height: 1px;
    min-width: 1px;
}

.plannings-tab-nav {
    left: 238px;
    right: 33px;
    transition: left 100ms linear;
}

.plannings-tab-nav.nav-sticky {
    background-color: white;
    position: fixed;
    top: 0;
    left: 204px;
    z-index: 10;
    right: 0;
    padding: 6px;
    border-bottom: 1px solid lightgray;
    transition: left 100ms linear, right 100ms linear;
    width: auto;
}

.plannings-tab-nav .nav-item {
    margin: 0 0.7rem;
    flex-basis: auto;
    display: block;
    width: auto;
}

.plannings-tab-nav .nav-link {
    font-size: 1.4rem;
}

.plannings-tab-nav.nav-sticky .nav-link {
    font-size: 1.2rem;
    transition: font-size 100ms linear;
}

.plannings-tab-nav .nav-item:first-child {
    margin-left: 0;
}

.plannings-tab-nav .nav-item:last-child {
    margin-right: 0;
}

a[id^="plannings-tab-tab"] {
    background-color: lightgrey;
    border-radius: 3px !important;
}

a[id^="plannings-tab-tab"] .active {
    border-radius: 3px !important;
}
