.artist-view-gallery .artist-view-gallery-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 3px;
}

.artist-view-gallery .artist-view-gallery-picture {
    width: 100%;
    height: auto;
    min-height: 200px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
}

.artist-view-gallery .artist-view-gallery-img {
    width: 100%;
    height: auto;
    min-height: 200px;
    display: grid;
    object-fit: cover;
    transform: scale(1);
    filter: blur(0);
    transition: 0.3s ease-in-out;
}

.artist-view-gallery
    .artist-view-gallery-picture:hover
    .artist-view-gallery-img {
    transform: scale(1.3);
    filter: blur(3px);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.artist-view-gallery
    .artist-view-gallery-picture:hover
    .artist-view-gallery-img-source {
    bottom: 0;
}

.artist-view-gallery .artist-view-gallery-img-source {
    position: absolute;
    right: 0;
    bottom: -23px;
    background-color: white;
    padding: 0.2rem 0.5rem 0.2rem 0.8rem;
    border-radius: 10px 0px 0px 0px;
    border: none;
    transition: bottom 0.3s ease-in-out;
}
