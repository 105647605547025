.recording-view-lyrics-lyrics-information .main-scroll {
    max-height: 8rem;
    overflow-y: auto;
}

.recording-view-lyrics-lyrics-information-nav .nav-item {
    margin: 0 0.7rem;
    flex-basis: auto;
    display: block;
    width: auto;
}

.recording-view-lyrics-lyrics-information-nav .nav-link {
    font-size: 1.4rem;
}

.recording-view-lyrics-lyrics-information-nav .nav-item:first-child {
    margin-left: 0;
}

.recording-view-lyrics-lyrics-information-nav .nav-item:last-child {
    margin-right: 0;
}

a[id^="recording-view-lyrics-lyrics-information-tab"] {
    background-color: lightgrey;
    border-radius: 3px !important;
}

.recording-view-lyrics-lyrics-information-nav > a.nav-item.nav-link.active {
    background-color: #3900d4 !important;
}
