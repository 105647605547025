.cnm-consolidation-form > header {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 1rem;
    padding: 0.75rem 1.25rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.cnm-consolidation-form .overlay {
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;
    background-color: rgba(256, 256, 256, 0.7);
    z-index: 100;
}

.cnm-consolidation-form,
.cnm-consolidation-form-footer {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
}

.cnm-consolidation-form .form-control[readOnly] {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: #555;
}

.cnm-consolidation-form .form-control.reference-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0 6px;
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: #555;
}

.cnm-consolidation-form .form-control.reference-list .btn {
    padding: 2px 5px;
}

.cnm-consolidation-form .form-control.reference-list > .btn {
    margin: 1.5px;
}

.cnm-consolidation-form .form-control.reference-list > .btn-group {
    margin: 1.5px;
}
