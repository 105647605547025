.status-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: none;
    margin-top: -20px;
    margin-left: auto;
    width: fit-content;
}

.status-filter-pending {
    margin-top: 2px;
}

.custom-switch {
    margin-top: -5px;
}
