#plannings-management-nav > div > a {
    background-color: lightgrey;
    border-radius: 3px !important;
}
#plannings-management-nav > div > a .active {
    border-radius: 3px !important;
}
#plannings-management-nav .nav-item {
    margin: 0 5px;
}
#plannings-management-nav .nav-item:first-child {
    margin-left: 0;
}
#plannings-management-nav .nav-item:last-child {
    margin-right: 0;
}
#plannings-management-nav-tab > .nav-item > a.active {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
#plannings-management-nav-tab > .btn-group > button {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
#plannings-management-tab-content {
    overflow: inherit;
}
