.form {
    position: relative;
    width: 100%;
}
.data {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    z-index: 9999999;
}
.head {
    padding: 0.5rem;
    background-color: #e9ecef;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}
.head:nth-child(1) {
    border-top: 0px;
}
.head__title {
    font-weight: bold;
}
.content__a {
    color: #495057;
    text-decoration: none;
}
.content__a:hover {
    color: #495057;
    text-decoration: none;
}
.content__a:nth-child(1) > .content__row {
    border-top: 0px;
}
.content__row {
    display: flex;
    padding: 0.5rem;
    border-top: 1px solid #ced4da;
}
.content__row:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
    cursor: pointer;
}
.content__row__empty {
    text-align: center;
    padding: 0.2rem 0px;
    font-size: 1.1rem;
}
.cover__artist {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #e9ecef;
}
.cover__artist > div > div {
    margin-right: -15px !important;
    margin-left: -15px !important;
}
.cover__album {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    object-fit: cover;
    background-color: #e9ecef;
}
.cover__album > div > div {
    margin-right: -15px !important;
    margin-left: -15px !important;
}
.cover__recording {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    object-fit: cover;
    background-color: #e9ecef;
}
.cover__recording > div > div {
    margin-right: -15px !important;
    margin-left: -15px !important;
}
.cover__placeholder {
    background-color: gray;
    object-fit: contain;
    padding: 0.2rem 0.4rem;
    opacity: 0.3;
}
.content__information {
    margin-left: 0.5rem;
    width: 100%;
}
.content__title {
    font-size: 1.1rem;
    font-weight: bold;
}
.content__subtitle {
    display: flex;
    align-items: center;
}
.input {
    padding-right: 28px;
}
.input__larger {
    height: 3.5rem;
    font-size: 1.2rem;
    padding-right: 40px;
}
.reset {
    position: absolute;
    right: 2px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
    z-index: 4;
}
.reset__larger {
    top: 9px;
    right: 6px;
    font-size: 25px;
}
.reset__shortcut {
    right: 79px;
}
.badge {
    padding: 0.25em 0.4em 0.35em 0.4em;
}
.explanation {
    padding: 8px;
    margin-bottom: 1rem;
}