.snep-file-management .content {
    min-height: 500px;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 0 30px;
}

.snep-file-management .breadcrumb {
    grid-area: 1 / 1 / 2 / 2;
}
