/* Breadcrumb */

.breadcrumb > li > a {
    color: #007bff;
}

/* Transformation de texte */
.capitalize {
    text-transform: capitalize;
}

.cursor-pointer {
    cursor: pointer;
}

.pre-wrap {
    white-space: pre-wrap;
}

.nowrap {
    white-space: nowrap;
}

/* Sidebar */
.logo {
    margin: 20px 0px 19px 0px;
    line-height: 20px;
}

.sub-logo {
    color: white;
    font-size: 12px;
    margin-left: 30px;
}

.sidebar-nav > li ul > li > a.active {
    font-weight: 400;
    color: #fff;
}

.sidebar-nav > li > a.active {
    color: #f2be35;
    background-color: #242424;
}

.sidebar-nav > li > a.active .icon {
    border-radius: 50%;
    background-color: #f2be35;
    color: #242424;
}

.dot {
    height: 13px;
    width: 13px;
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    background-color: #6c757d;
}

.dot-primary {
    background-color: #007bff;
}

/* Fixe le header d'un tableau */
.table-header-fixed th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 30;
}

.table-bordered-header-fixed th {
    position: sticky;
    top: -1px;
    z-index: 30;
}

.table-selectable > tbody > tr {
    cursor: pointer;
}

.table-disabled {
    cursor: default !important;
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.075) !important;
}

/* Fixe la ligne sélectionné dans un tableau */
.table-info > td {
    position: sticky;
    top: 0;
    bottom: 0;
}
.table-header-fixed .table-info > td {
    top: 27px;
}
.table-header-fixed.table-sm .table-info > td {
    top: 25px;
}

.table-bordered-header-fixed .table-info > td {
    top: 31px;
}

.table-bordered-header-fixed.table-sm .table-info > td {
    top: 27px;
}

.top-footer {
    font-weight: bold;
    margin-top: 27px;
    float: left;
}

/* Css pour les boutons du plugin widgster */
.data-widgster {
    padding: 1px 4px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.4);
    transition: color 0.15s ease-in-out;
    cursor: pointer;
}
.data-widgster:hover {
    color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
}

/* Centrer les boutons des sweet alerts */
.swal-footer {
    text-align: center;
}

/* Change le style des boutons de sweet alerts */
.swal-button {
    font-weight: 400;
    display: inline-block;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.75rem 1.25rem !important;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.swal-button--confirm {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.swal-button--confirm:not([disabled]):hover {
    background-color: #218838;
}

.swal-button--cancel {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.swal-button--cancel:not([disabled]):hover {
    background-color: #5a6268;
}

.swal-button--danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.swal-button--danger:not([disabled]):hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.swal-button--validate {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.swal-button--validate:not([disabled]):hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

/* Changer le display des datePickers */
.react-datepicker-wrapper {
    display: block;
}

/* Bordure de couleur dans les tableaux */
.table tbody tr.success td:first-child {
    border-left: 3px solid #34a853;
    padding-left: 5px;
}

/*
 * Grey table row. This uses the same style as table-striped, but lets you pick
 * which row should be grey.
 */
.table tbody tr.row-striped {
    background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr.row-striped:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
}
.table-hover tbody tr.font-weight-bold-hover:hover {
    font-weight: bold;
}

/* Button */
.btn-success-auto {
    color: #000;
    background-color: #b0f2b6;
    border-color: #b0f2b6;
}

.btn-success-auto:hover {
    color: #000;
    background-color: #95ca99;
    border-color: #95ca99;
}

button.disabled {
    cursor: default !important;
    pointer-events: none !important;
}

/* Texte */
.text-success-auto {
    color: #b0f2b6;
}

/* Dropdown */
span.dropdown {
    display: inline-block !important;
}

.dropdown-item.active-secondary.active,
.dropdown-item.active-secondary:active {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d;
}

.dropdown-item.active-primary.active,
.dropdown-item.active-primary:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
}

.dropdown-item.active-warning.active,
.dropdown-item.active-warning:active {
    color: #000;
    text-decoration: none;
    background-color: #ffc107;
}

.dropdown-item.active-success.active,
.dropdown-item.active-success:active {
    color: #fff;
    text-decoration: none;
    background-color: #28a745;
}

.dropdown-item.active-success-auto.active,
.dropdown-item.active-success-auto:active {
    color: #000;
    text-decoration: none;
    background-color: #b0f2b6;
}

.dropdown-item.active-danger.active,
.dropdown-item.active-danger:active {
    color: #fff;
    text-decoration: none;
    background-color: #dc3545;
}

/* React datepicker */
.react-datepicker-popper {
    z-index: 40;
}

/* Use bootstrap's blue for pills that are in a widget */
.widget .nav-pills .nav-link.active,
.widget .nav-pills .show > .nav-link {
    background-color: #3900d4 !important;
}
.nav-link {
    font-size: 1.5rem;
    color: #3900d4;
}

.nav-pills .nav-link.active {
    background-color: #3900d4 !important;
}

.nav-link:hover:not(.active) {
    color: #1f0074;
}

/* Display an image with the same size has a font-awesome icon that has size="lg" */
img.icon-lg {
    height: 1.33333em;
}

/* Accordion arrow */
.card-title > button::before {
    content: "";
    border: solid #007bff;
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(-135deg);
    display: inline-block;
    margin: 0px 8px 1px 0px;
    transition: all 0.5s;
}
.card-title > button[aria-expanded="true"]::before {
    transform: rotate(45deg);
}

/* The colors used in the following classes are the same as the ones used in
 * Bootstrap's table-<color> classes.
 * Use <div class="legend-dot legend-<color>"></div> to display a colored dot
 * in a legend. */
.legend-dot {
    display: inline-block;
    width: 9px;
    height: 9px;
    border: solid 1px;
    border-radius: 50%;

    background-color: #ffffff;
    border-color: #dee2e6;
}
.legend-primary {
    background-color: #b8daff;
    border-color: #7abaff;
}
.legend-secondary {
    background-color: #d6d8db;
    border-color: #b3b7bb;
}
.legend-success {
    background-color: #c3e6cb;
    border-color: #8fd19e;
}
.legend-info {
    background-color: #bee5eb;
    border-color: #86cfda;
}
.legend-warning {
    background-color: #ffeeba;
    border-color: #ffdf7e;
}
.legend-danger {
    background-color: #f5c6cb;
    border-color: #ed969e;
}
.legend-light {
    background-color: #fdfdfe;
    border-color: #fbfcfc;
}
.legend-dark {
    background-color: #c6c8ca;
    border-color: #95999c;
}

.alert-dismissible .small-close {
    padding: 1rem 1.25rem;
    font-size: 1rem;
}

.widget-alert {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
    border-left: none;
    border-right: none;
}

/* This applies the same style as Bootstrap's text-truncate to all the cells of
 * a table.
 * This prevents the cell's text from wrapping, and truncate it with an
 * ellipsis ("..."). */
.table-truncate td,
.table-truncate th {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* Use this class on a table cell that contains an input to make the input fill
 * the cell. */
td.cell-edit {
    padding: 0;
}

td.cell-edit .form-control {
    border-radius: 0;
    height: 100%;
    /* same padding as td, minus 1px to compensate for the input's border */
    padding: calc(0.55rem - 1px);
}

.table-sm td.cell-edit {
    padding: 0;
}

.table-sm td.cell-edit .form-control {
    border-radius: 0;
    height: 100%;
    /* same padding as td, minus 1px to compensate for the input's border */
    padding: calc(0.4rem - 1px);
}

.table-layout-auto {
    table-layout: auto !important;
}

.vertical-shrink {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.badge-lg {
    font-size: 100%;
}
.badge-success-auto {
    color: #000;
    background-color: #b0f2b6;
    border-color: #b0f2b6;
}

label {
    cursor: pointer;
    display: inline;
}

.bg-gradient {
    background: linear-gradient(
        to right,
        rgba(233, 51, 246, 0.5),
        rgba(51, 4, 202, 0.5)
    );
}

.grecaptcha-badge {
    visibility: hidden;
}

.bg-indigo {
    background: #3900d4;
}

.bg-purple-ms {
    background: #911ce1;
}

.bg-success-auto {
    background: #b0f2b6;
}

.modal-add-image .modal-body {
    height: 75vh;
}

.font-feature-settings-tnum {
    font-feature-settings: "tnum";
}
