.cardHeader {
    border-bottom: 1px solid lightgray;
}

.nav {
    display: flex;
    justify-content: space-between;
}

.navItem {
    flex: 1;
    text-align: center;
    position: relative;
}

.navLink {
    width: 100%;
    padding: 1rem;
}

.navLink:hover {
    color: #3900d4 !important;
}

.navLink::after {
    content: "";
    background: #3900d4;
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    bottom: 0;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.navLink.active::after {
    transform: scale(1);
}
