.widget .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 50;
    opacity: 0.4;
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 3px;
    padding-top: 1rem;
}
