.image-crop-modal label {
    display: inline;
    cursor: pointer;
}
.image-crop-modal .container-preview {
    width: 300px;
    height: 300px;
    background: #eee;
}
.image-crop-modal .img-container img {
    max-width: 100%;
}
.image-crop-modal .img-preview {
    overflow: hidden;
    width: 300px;
    max-width: 300px;
    height: 300px;
    max-height: 300px;
    padding-top: 0;
    padding-left: 0;
}

.swal-modal .swal-text {
    text-align: center;
}
