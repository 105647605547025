.body {
    min-height: 20rem;
    margin: 15px -20px 0px -20px !important;
}
.tippy {
    margin: -5px -9px;
}
.disco {
    padding-left: 12px;
}
