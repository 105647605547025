.apple__player__volume {
    display: flex;
    align-self: center;
    align-items: center;
    width: 120px;
}
.apple__player__volume > input {
    cursor: pointer;
    outline: none;
}
