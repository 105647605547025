.apple__sample__assessment__lyric__row.with__sync__tag > td:nth-child(n + 3) {
    border: none !important;
}
.apple__sample__assessment__lyric__row.without__sync__tag
    > td:nth-child(n + 2) {
    border: none !important;
}
.apple__sample__assessment__lyric__row.apple__sample__assessment__lyric__empty__row
    > td {
    border: none !important;
}
.apple__sample__assessment__lyric__empty__row {
    height: 28px;
}
.apple__sample__assessment__lyric__empty__row:hover {
    background-color: white !important;
}
.apple__sample__assessment__lyric__timing {
    display: grid;
    grid-template-columns: 1fr fit-content(0) 1fr;
    grid-gap: 8px;
}
.apple__sample__assessment__lyric__current__row {
    background-color: #fff1c2;
}
.apple__sample__assessment__lyric__expanded__row {
    background-color: whitesmoke;
    padding: 0.4rem !important;
}
.apple__sample__assessment__lyric__expanded__row:hover {
    background-color: whitesmoke;
}
.apple__sample__assessment__lyric__text__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.apple__sample__assessment__lyric__text__comment {
    cursor: pointer;
}
.apple__sample__assessment__lyric__widget__body {
    overflow: auto;
}
.apple__sample__assessment__lyric__widget__col-1 {
    grid-area: 4 / 1 / 5 / 4;
}
.apple__sample__assessment__lyric__widget__col-2-left {
    grid-area: 4 / 2 / 5 / 4;
    margin-left: 1rem;
}
.apple__sample__assessment__lyric__widget__col-2-right {
    grid-area: 4 / 1 / 5 / 3;
    margin-right: 1rem;
}
.apple__sample__assessment__lyric__widget__col-3 {
    grid-area: 4 / 2 / 5 / 3;
    margin-right: 1rem;
    margin-left: 1rem;
}
