.universal-track-assignment .content {
    height: 100%;
    display: grid;
    grid-template-columns: 0.1fr 0.9fr;
    grid-template-rows: auto 1fr;
    gap: 0 0.7rem;
}

.universal-track-assignment .breadcrumb {
    grid-area: 1 / 1 / 2 / 3;
}

.universal-track-assignment .grid-left {
    grid-area: 2 / 1 / 3 / 2;
}

.universal-track-assignment .grid-right {
    grid-area: 2 / 2 / 3 / 3;
}
