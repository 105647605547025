.artist-management-bas-card {
    height: fit-content;
}

.artist-management-bas-card .card-footer {
    padding: 0.25rem 0.25rem;
}

.artist-management-bas-card .image-container {
    position: relative;
}

.artist-management-bas-card .image-container .image-content-over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
}

.artist-management-bas-card .image-container:hover .image-content-over {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}
