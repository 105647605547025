.misc-entity-management-curation-card {
    position: relative;
}

.misc-entity-management-curation-card .delete-button {
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 1000;
}

.misc-entity-management-curation-card .card-without-delete {
    height: fit-content;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}

.misc-entity-management-curation-card .card-footer {
    padding: 0.25rem 0.25rem;
}

.misc-entity-management-curation-card .image-container {
    position: relative;
}

.misc-entity-management-curation-card .image-container .image-content-over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
}

.misc-entity-management-curation-card
    .image-container:hover
    .image-content-over {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}
