.recording-view-popularity-histogram-popover .cover-art {
    height: 9rem;
    object-fit: contain;
    border-radius: 0.25rem;
}

.recording-view-popularity-histogram-popover th,
.recording-view-popularity-histogram-popover td {
    padding: 0.25rem;
}
