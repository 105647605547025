.apple__sample__assessment__player__fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 204px);
    background-color: white;
    height: 76px;
}
.apple__sample__assessment__player__fixed__shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    opacity: 0.7;
    color: white;
    font-size: 2rem;
}
