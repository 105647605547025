.cardHeader {
    border-bottom: 1px solid lightgray;
}

.navItem {
    position: relative;
}

.navLink {
    padding: 1rem;
}

.navLink:hover {
    color: #3900d4 !important;
}

.navLink::after {
    content: "";
    background: #3900d4;
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    bottom: 0;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.navLink.active::after {
    transform: scale(1);
}
