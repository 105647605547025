.disco-sandbox-popover .cover-art {
    display: block;
    margin: auto;
    width: 17rem;
    height: 17rem;
    object-fit: contain;
}

.disco-sandbox-popover th,
.disco-sandbox-popover td {
    padding: 0.25rem;
}

/* Reset bootstrap style which is overwriten by the legacy's CSS */
.popover {
    border: 1px solid rgba(0, 0, 0, 0.2);
}
