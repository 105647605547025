:root {
    --toastify-color-light: #404040;
    --toastify-color-dark: #404040;
    --toastify-color-info: #404040;
    --toastify-color-success: #404040;
    --toastify-color-warning: #404040;
    --toastify-color-error: #404040;
    --toastify-icon-color-info: #61c4b8;
    --toastify-icon-color-success: #5fca4a;
    --toastify-icon-color-warning: #f1c40f;
    --toastify-icon-color-error: #dd6a45;
    --toastify-toast-min-height: 45px;
    --toastify-text-color-light: #f0f0f0;
    --toastify-text-color-dark: #f0f0f0;
    --toastify-text-color-info: #f0f0f0;
    --toastify-text-color-success: #f0f0f0;
    --toastify-text-color-warning: #f0f0f0;
    --toastify-text-color-error: #f0f0f0;
    --toastify-color-progress-info: #61c4b8;
    --toastify-color-progress-success: #5fca4a;
    --toastify-color-progress-warning: #f1c40f;
    --toastify-color-progress-error: #dd6a45;
}

.Toastify__toast-container {
    padding: 0;
    width: auto;
    min-width: var(--toastify-toast-width);
    max-width: 400px;
}

.Toastify__toast {
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.13), inset 48px 0 0 #292929;
    padding: 8px 8px 8px 56px;
    font-weight: 500;
    font-size: 13px;
}

.Toastify__toast--light .Toastify__toast-body:before {
    background: #fff;
    color: #aaa;
}
.Toastify__toast--info .Toastify__toast-body:before {
    background: #61c4b8;
}
.Toastify__toast--success .Toastify__toast-body:before {
    background: #5fca4a;
}
.Toastify__toast--warning .Toastify__toast-body:before {
    background: #f1c40f;
}
.Toastify__toast--error .Toastify__toast-body:before {
    background: #dd6a45;
}
.Toastify__toast--default .Toastify__toast-body:before {
    background: #fff;
}
.Toastify__toast-body:before {
    border-radius: 50%;
    position: absolute;
    left: 17px;
    display: block;
    content: " ";
    top: 50%;
    margin-top: -8px;
    height: 13px;
    width: 13px;
    z-index: 20;
}

.Toastify__close-button {
    color: #888 !important;
    opacity: 0.7 !important;
}

.Toastify__close-button:focus {
    outline: none;
}
