.miscentity-management .nav-pills {
    float: right;
}
.miscentity-management .row {
    margin-right: -0.7rem;
    margin-left: -0.7rem;
}
.miscentity-management .col,
.miscentity-management .col-2,
.miscentity-management .col-3,
.miscentity-management .col-4,
.miscentity-management .col-5,
.miscentity-management .col-6,
.miscentity-management .col-7,
.miscentity-management .col-8,
.miscentity-management .col-9,
.miscentity-management .col-10,
.miscentity-management .col-11,
.miscentity-management .col-12 {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
}
.miscentity-management .widget {
    margin-bottom: 1.4rem;
}
.miscentity-management a.nav-link {
    line-height: 1.5;
    font-size: 1rem;
}
