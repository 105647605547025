.popover {
    min-width: 12vw;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pourcentage {
    font-weight: bold;
}
