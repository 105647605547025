.apple__sample__assessment__lyric__form__textarea {
    height: 89px !important;
}
.apple__sample__assessment__lyric__form__textarea.disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
}
.apple__sample__assessment__lyric__form__textarea.is-invalid {
    border-color: #dc3545;
}
