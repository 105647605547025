.picture-label-gallery .expired img {
    opacity: 0.3;
}

.picture-label-gallery .image-container {
    position: relative;
}

.picture-label-gallery .image-container .image-content-over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
}

.picture-label-gallery .image-container:hover .image-content-over {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}
