.cnm-aggregation .content {
    min-height: 900px;
    height: 100%;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: auto 1fr auto;
    gap: 0 30px;
}

.cnm-aggregation .breadcrumb {
    grid-area: 1 / 1 / 2 / 3;
}

.cnm-aggregation .grid-bottom {
    grid-area: 3 / 1 / 4 / 3;
}
