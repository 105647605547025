.button {
    background: #00a2ff;
    border-color: #00a2ff;
}

.button:hover {
    background: #3900d4;
    border-color: #3900d4;
}

.button.active {
    background: #3900d4 !important;
    border-color: #3900d4 !important;
}
