.btn-blue {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-blue:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn-blue:focus,
.btn-blue.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-blue.disabled,
.btn-blue:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-indigo {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2;
}
.btn-indigo:hover {
    color: #fff;
    background-color: #560bd0;
    border-color: #510bc4;
}
.btn-indigo:focus,
.btn-indigo.focus {
    color: #fff;
    background-color: #560bd0;
    border-color: #510bc4;
    box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5);
}
.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2;
}
.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #510bc4;
    border-color: #4c0ab8;
}
.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5);
}

.btn-purple {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}
.btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #59339d;
}
.btn-purple:focus,
.btn-purple.focus {
    color: #fff;
    background-color: #5e37a6;
    border-color: #59339d;
    box-shadow: 0 0 0 0.2rem rgba(133, 94, 202, 0.5);
}
.btn-purple.disabled,
.btn-purple:disabled {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}
.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59339d;
    border-color: #533093;
}
.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 94, 202, 0.5);
}

.btn-pink {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c;
}
.btn-pink:hover {
    color: #fff;
    background-color: #e41c78;
    border-color: #d91a72;
}
.btn-pink:focus,
.btn-pink.focus {
    color: #fff;
    background-color: #e41c78;
    border-color: #d91a72;
    box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5);
}
.btn-pink.disabled,
.btn-pink:disabled {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c;
}
.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #d91a72;
    border-color: #ce196c;
}
.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5);
}

.btn-red {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-red:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.btn-red:focus,
.btn-red.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-red.disabled,
.btn-red:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-red:not(:disabled):not(.disabled):active,
.btn-red:not(:disabled):not(.disabled).active,
.show > .btn-red.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}
.btn-red:not(:disabled):not(.disabled):active:focus,
.btn-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-red.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-orange {
    color: #212529;
    background-color: #fd7e14;
    border-color: #fd7e14;
}
.btn-orange:hover {
    color: #fff;
    background-color: #e96b02;
    border-color: #dc6502;
}
.btn-orange:focus,
.btn-orange.focus {
    color: #fff;
    background-color: #e96b02;
    border-color: #dc6502;
    box-shadow: 0 0 0 0.2rem rgba(220, 113, 23, 0.5);
}
.btn-orange.disabled,
.btn-orange:disabled {
    color: #212529;
    background-color: #fd7e14;
    border-color: #fd7e14;
}
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #dc6502;
    border-color: #cf5f02;
}
.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 113, 23, 0.5);
}

.btn-yellow {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-yellow:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}
.btn-yellow:focus,
.btn-yellow.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-yellow.disabled,
.btn-yellow:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-yellow:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.show > .btn-yellow.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}
.btn-yellow:not(:disabled):not(.disabled):active:focus,
.btn-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-green {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-green:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}
.btn-green:focus,
.btn-green.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-green.disabled,
.btn-green:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}
.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-teal {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997;
}
.btn-teal:hover {
    color: #fff;
    background-color: #1ba87e;
    border-color: #199d76;
}
.btn-teal:focus,
.btn-teal.focus {
    color: #fff;
    background-color: #1ba87e;
    border-color: #199d76;
    box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5);
}
.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997;
}
.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show > .btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #199d76;
    border-color: #17926e;
}
.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5);
}

.btn-cyan {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-cyan:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}
.btn-cyan:focus,
.btn-cyan.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-cyan.disabled,
.btn-cyan:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-cyan:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled).active,
.show > .btn-cyan.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.btn-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-outline-blue {
    color: #007bff;
    border-color: #007bff;
}
.btn-outline-blue:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-outline-blue:focus,
.btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
    color: #007bff;
    background-color: transparent;
}
.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-indigo {
    color: #6610f2;
    border-color: #6610f2;
}
.btn-outline-indigo:hover {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2;
}
.btn-outline-indigo:focus,
.btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
}
.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
    color: #6610f2;
    background-color: transparent;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active,
.btn-outline-indigo:not(:disabled):not(.disabled).active,
.show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2;
}
.btn-outline-indigo:not(:disabled):not(.disabled):active:focus,
.btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
}

.btn-outline-purple {
    color: #6f42c1;
    border-color: #6f42c1;
}
.btn-outline-purple:hover {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}
.btn-outline-purple:focus,
.btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}
.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active,
.btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

.btn-outline-pink {
    color: #e83e8c;
    border-color: #e83e8c;
}
.btn-outline-pink:hover {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c;
}
.btn-outline-pink:focus,
.btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}
.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent;
}
.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c;
}
.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.btn-outline-red {
    color: #dc3545;
    border-color: #dc3545;
}
.btn-outline-red:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-outline-red:focus,
.btn-outline-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-red.disabled,
.btn-outline-red:disabled {
    color: #dc3545;
    background-color: transparent;
}
.btn-outline-red:not(:disabled):not(.disabled):active,
.btn-outline-red:not(:disabled):not(.disabled).active,
.show > .btn-outline-red.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-outline-red:not(:disabled):not(.disabled):active:focus,
.btn-outline-red:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-orange {
    color: #fd7e14;
    border-color: #fd7e14;
}
.btn-outline-orange:hover {
    color: #212529;
    background-color: #fd7e14;
    border-color: #fd7e14;
}
.btn-outline-orange:focus,
.btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}
.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
    color: #fd7e14;
    background-color: transparent;
}
.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
    color: #212529;
    background-color: #fd7e14;
    border-color: #fd7e14;
}
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5);
}

.btn-outline-yellow {
    color: #ffc107;
    border-color: #ffc107;
}
.btn-outline-yellow:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-outline-yellow:focus,
.btn-outline-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
    color: #ffc107;
    background-color: transparent;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active,
.btn-outline-yellow:not(:disabled):not(.disabled).active,
.show > .btn-outline-yellow.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active:focus,
.btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-yellow.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-green {
    color: #28a745;
    border-color: #28a745;
}
.btn-outline-green:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-outline-green:focus,
.btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-green.disabled,
.btn-outline-green:disabled {
    color: #28a745;
    background-color: transparent;
}
.btn-outline-green:not(:disabled):not(.disabled):active,
.btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-outline-green:not(:disabled):not(.disabled):active:focus,
.btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-teal {
    color: #20c997;
    border-color: #20c997;
}
.btn-outline-teal:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997;
}
.btn-outline-teal:focus,
.btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}
.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
    color: #20c997;
    background-color: transparent;
}
.btn-outline-teal:not(:disabled):not(.disabled):active,
.btn-outline-teal:not(:disabled):not(.disabled).active,
.show > .btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997;
}
.btn-outline-teal:not(:disabled):not(.disabled):active:focus,
.btn-outline-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
}

.btn-outline-cyan {
    color: #17a2b8;
    border-color: #17a2b8;
}
.btn-outline-cyan:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-outline-cyan:focus,
.btn-outline-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-cyan.disabled,
.btn-outline-cyan:disabled {
    color: #17a2b8;
    background-color: transparent;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active,
.btn-outline-cyan:not(:disabled):not(.disabled).active,
.show > .btn-outline-cyan.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
.btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-cyan.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.text-blue {
    color: #007bff;
}

.text-indigo {
    color: #6610f2;
}

.text-purple {
    color: #6f42c1;
}

.text-pink {
    color: #e83e8c;
}

.text-red {
    color: #dc3545;
}

.text-orange {
    color: #fd7e14;
}

.text-yellow {
    color: #ffc107;
}

.text-green {
    color: #28a745;
}

.text-teal {
    color: #20c997;
}

.text-cyan {
    color: #17a2b8;
}
