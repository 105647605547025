.artist-followup .content {
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    gap: 0 0.7rem;
}

.artist-followup .breadcrumb {
    grid-area: 1 / 1 / 2 / 2;
}

.artist-followup .dashboard {
    grid-area: 2 / 1 / 3 / 2;
}
