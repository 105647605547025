.popover {
    min-width: 12vw;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pourcentage {
    font-weight: bold;
}

.content {
    padding: 16px;
}

.text {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.table {
    width: 100%;
}
