.footer {
    color: rgba(255, 255, 255, 0.5);
    padding: 20px 0px;
}

.authPageContent {
    position: relative;
    z-index: 2;
    width: 100%;
}

.authBgCover {
    background-image: url("assets/static/images/why-hero-bg@2x.png");
}
