.step {
    width: 100%;
    position: relative;
}

.circle {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background-color: #e0e0e0;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeCircle {
    background-color: #3900d4;
}

.completedCircle {
    background-color: #28a745;
}

.index {
}

.title {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    display: block;
    color: #757575;
}

.activeTitle {
    color: #000;
}

.completedTitle {
    color: #000;
}

.leftBar {
    border: 1px solid #e0e0e0;
    position: absolute;
    width: calc(50% - 16px);
    top: 15px;
}

.rightBar {
    border: 1px solid #e0e0e0;
    position: absolute;
    width: calc(50% - 16px);
    top: 15px;
    right: 0px;
}

.completedBar {
    border: 1px solid #e0e0e0;
}
