.artist-management-getty-card {
    height: fit-content;
}

.artist-management-getty-card .card-shadow {
    height: fit-content;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}

.artist-management-getty-card .card-footer {
    padding: 0.25rem 0.25rem;
}

.artist-management-getty-card .image-container {
    position: relative;
}

.artist-management-getty-card .image-container .image-content-over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
}

.artist-management-getty-card .image-container:hover .image-content-over {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}
