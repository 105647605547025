.cnm-repositories .content {
    min-height: 700px;
    height: 100%;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: auto auto 1fr;
    gap: 0 30px;
}

.cnm-repositories .breadcrumb {
    grid-area: 1 / 1 / 2 / 3;
}

.cnm-repositories .grid-bottom {
    grid-area: 3 / 1 / 4 / 3;
}

.cnm-repositories .grid-bottom-left {
    grid-area: 3 / 1 / 4 / 2;
}

.cnm-repositories .grid-bottom-right {
    grid-area: 3 / 2 / 4 / 3;
}
