.search-view-nav .nav-item {
    margin: 0 0.7rem;
}

.search-view-nav .nav-item:first-child {
    margin-left: 0;
}

.search-view-nav .nav-item:last-child {
    margin-right: 0;
}

a[id^="search-view-tab"] {
    background-color: lightgrey;
    border-radius: 3px !important;
}

a[id^="search-view-tab"] .active {
    border-radius: 3px !important;
}

.search .cover-artist {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #e9ecef;
}

.search .cover-album {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    object-fit: cover;
    background-color: #e9ecef;
}

.search .cover-recording {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    object-fit: cover;
    background-color: #e9ecef;
}

.search .cover-placeholder {
    background-color: gray;
    object-fit: contain;
    padding: 0.2rem 0.4rem;
    opacity: 0.3;
}

.search .custom-column {
    max-width: 8vw;
    flex-wrap: wrap;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
