.artists-tooltip {
    font-size: 100%;
}
.artists-tooltip .tooltip-inner {
    max-width: 600px;
}
.react-bootstrap-table-pagination {
    align-items: center;
}

.react-bootstrap-table-pagination > div:first-child > div {
    margin-bottom: 0 !important;
}
