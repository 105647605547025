tr div.nbtrack-container {
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

tr div.actions {
    top: -4px;
    position: absolute;
    right: 0;
    display: none;
    background-color: #ececec;
    padding-left: 7px;
}

tr:hover div.actions {
    display: block;
}
