.artist-management-label-card {
    height: fit-content;
}

.artist-management-label-card .card-footer {
    padding: 0.25rem 0.25rem;
}

.artist-management-label-card .image-container {
    position: relative;
}

.artist-management-label-card .image-container .image-content-over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
}

.artist-management-label-card .picture-show {
    display: none;
}

.artist-management-label-card.expired .image-content-over {
    display: block;
    background: rgba(0, 0, 0, 0.4);
}

.artist-management-label-card .image-container:hover .image-content-over {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.artist-management-label-card .image-container:hover .picture-show {
    display: flex;
}
