.conflicts .nav-pills {
    float: none !important;
}

.conflicts .nav-item {
    margin: 0 0.7rem;
}

.conflicts .nav-item:first-child {
    margin-left: 0;
}

.conflicts .nav-item:last-child {
    margin-right: 0;
}

.conflicts a.nav-link {
    font-size: 1.3rem !important;
}

a[id^="conflicts-tab"] {
    background-color: lightgrey;
    border-radius: 3px !important;
}

a[id^="conflicts-tab"] .active {
    border-radius: 3px !important;
}
