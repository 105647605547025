.album-view .row {
    margin-right: -0.7rem;
    margin-left: -0.7rem;
}

.album-view .col,
.album-view .col-2,
.album-view .col-3,
.album-view .col-4,
.album-view .col-5,
.album-view .col-6,
.album-view .col-7,
.album-view .col-8,
.album-view .col-9,
.album-view .col-10,
.album-view .col-11,
.album-view .col-12 {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
}

.album-view .widget {
    margin-bottom: 1.4rem;
}

.album-view #header-observer {
    background-color: transparent;
    min-height: 1px;
    min-width: 1px;
}

.album-view .header-img {
    border-radius: 3px;
    width: 10rem;
    height: 10rem;
    transition: width 100ms linear, height 100ms linear;
}

.album-view .placeholder-img {
    background-color: gray;
    object-fit: contain;
    padding: 2rem;
    opacity: 0.3;
}

.album-view .header {
    margin-bottom: 1.2rem;
    left: 238px;
    transition: left 100ms linear;
}

.album-view .header-data {
    margin-left: 1.2rem;
}

.album-view .header-sticky {
    background-color: white;
    margin: 0;
    position: fixed;
    top: 0;
    left: 204px;
    z-index: 10;
    right: 0;
    border-bottom: 1px solid lightgray;
    transition: left 100ms linear;
}

.album-view .header-sticky .header-img {
    width: 7rem;
    height: 7rem;
    margin: 6px 0px 6px 6px;
    transition: width 100ms linear, height 100ms linear;
}

.album-view .header-sticky h4 {
    font-size: 1.2rem;
    transition: font-size 100ms linear;
}

.album-view .custom-column {
    max-width: 3.5vw;
}

.album-view .custom-column-cpline {
    max-width: 10vw;
}

.album-view-nav {
    left: 238px;
    right: 33px;
    transition: left 100ms linear;
}

.album-view-nav.nav-sticky {
    background-color: white;
    position: fixed;
    top: 97px;
    left: 204px;
    z-index: 10;
    right: 0;
    padding: 6px;
    border-bottom: 1px solid lightgray;
    transition: left 100ms linear, right 100ms linear;
    width: auto;
}

.album-view-nav .nav-item {
    margin: 0 0.7rem;
    flex-basis: auto;
    display: block;
    width: auto;
}

.album-view-nav .nav-link {
    font-size: 1.4rem;
}

.album-view-nav.nav-sticky .nav-link {
    font-size: 1.2rem;
    transition: font-size 100ms linear;
}

.album-view-nav .nav-item:first-child {
    margin-left: 0;
}

.album-view-nav .nav-item:last-child {
    margin-right: 0;
}

a[id^="album-view-tab"] {
    background-color: lightgrey;
    border-radius: 3px !important;
}

a[id^="album-view-tab"] .active {
    border-radius: 3px !important;
}
