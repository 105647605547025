.genre-view .content {
    height: 100%;
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-rows: auto auto 0.4fr 0.6fr;
    gap: 1rem;
}

.genre-view .breadcrumb {
    grid-area: 1 / 1 / 2 / 3;
}

.genre-view .search-management {
    grid-area: 2 / 1 / 3 / 3;
}

.genre-view .genre {
    grid-area: 3 / 1 / 5 / 2;
}

.genre-view .related {
    grid-area: 3 / 2 / 4 / 2;
}

.genre-view .artist {
    grid-area: 4 / 2 / 5 / 3;
}
