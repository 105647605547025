.apple__sample__assessment__ratings__left {
    grid-area: 4 / 1 / 5 / 2;
}
.apple__sample__assessment__ratings__right {
    grid-area: 4 / 3 / 5 / 4;
}
.apple__sample__assessment__ratings__container {
    overflow: auto;
}
.apple__sample__assessment__ratings__container > div.card:last-child {
    margin-bottom: 0 !important;
}
