.artist-management .nav-pills {
    float: right;
}
.artist-management .row {
    margin-right: -0.7rem;
    margin-left: -0.7rem;
}
.artist-management .col,
.artist-management .col-2,
.artist-management .col-3,
.artist-management .col-4,
.artist-management .col-5,
.artist-management .col-6,
.artist-management .col-7,
.artist-management .col-8,
.artist-management .col-9,
.artist-management .col-10,
.artist-management .col-11,
.artist-management .col-12 {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
}
.artist-management .widget {
    margin-bottom: 1.4rem;
}
.artist-management a.nav-link {
    line-height: 1.5;
    font-size: 1rem;
}
