.industry-statistics #header-observer {
    background-color: transparent;
    min-height: 1px;
    min-width: 1px;
}

.industry-statistics .header {
    margin-bottom: 1.2rem;
    left: 238px;
    transition: left 100ms linear;
}

.industry-statistics .header-sticky {
    background-color: white;
    position: fixed;
    top: 46px;
    left: 204px;
    z-index: 10;
    right: 0;
    padding: 6px;
    border-bottom: 1px solid lightgray;
    transition: font-size 100ms linear;
    width: auto;
}

.industry-statistics .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 29px;
}

.industry-statistics #input-select {
    position: absolute;
    left: 0;
}

.industry-statistics .input-select {
    width: 12vw;
    font-weight: bold;
}

.industry-statistics .title-text {
    font-weight: bold;
    text-align: center;
    margin-bottom: 0rem;
}

.industry-statistics .column-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.875rem;
}
