.subtitle {
    color: white;
    margin-left: 75px;
}

.authOneBg {
    background-color: black;
    background-image: url("assets/static/images/felipepelaquim-Ig_bBX3fyjo-unsplash_LR_Extended.psd_1.jpg");
    background-position: 47% 35%;
    background-repeat: no-repeat;
    background-size: cover;
}
.authOneBg::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: url("assets/static/images/home-overlay-gradient-hero.svg");
    background-size: auto;
    background-size: cover !important;
    mix-blend-mode: soft-light !important;
}

.indicator {
    display: inline-block;
    color: white;
    cursor: pointer;
    list-style: none;
    background-color: white;
    height: 3px;
    width: 30px;
    margin: 0px 3px;
    opacity: 0.5;
}

.selected {
    opacity: 1;
}
