.apple__player__action {
    display: flex;
    justify-content: center;
    align-self: center;
}
.apple__player__action > span {
    margin: 0 10px;
    font-size: 24px;
    cursor: pointer;
}
.apple__player__action > span:hover {
    color: black;
}
