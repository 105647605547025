.custom-switch.custom-switch-md {
    padding-left: 3.25rem;
}

.custom-switch.custom-switch-md .custom-control-label {
    padding-left: 1rem;
    padding-top: 3px;
    font-weight: bold;
    display: inline-block;
    margin-left: -7px;
}

.custom-switch.custom-switch-md .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md
    .custom-control-input:checked
    ~ .custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
}

.custom-switch.custom-switch-md.disabled > label {
    cursor: auto;
}
