.aritst-view-profile-external-link .facebook:hover {
    color: #3b5998;
}
.aritst-view-profile-external-link .twitter:hover {
    color: #000000;
}
.aritst-view-profile-external-link .youtube:hover {
    color: #c4302b;
}
.aritst-view-profile-external-link .soundcloud:hover {
    color: #ff7700;
}
.aritst-view-profile-external-link .instagram:hover {
    color: #3f729b;
}
.aritst-view-profile-external-link .tiktok:hover {
    color: #000000;
}
