.cnm-consolidation .content {
    min-height: 700px;
    height: 100%;
    display: grid;
    grid-template-columns: 0.25fr 0.75fr;
    grid-template-rows: auto 1fr;
    gap: 0 30px;
}

.cnm-consolidation .breadcrumb {
    grid-area: 1 / 1 / 2 / 3;
}
