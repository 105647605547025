a[id^="cnm-charts-tab"] {
    background-color: lightgrey;
    border-radius: 3px !important;
}

a[id^="cnm-charts-tab"] .active {
    border-radius: 3px !important;
}

.cnm-charts .nav-item {
    margin: 0 15px;
}
.cnm-charts .nav-item:first-child {
    margin-left: 0;
}
.cnm-charts .nav-item:last-child {
    margin-right: 0;
}
