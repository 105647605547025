.body {
    min-height: 20rem;
    margin: 15px -20px 0px -20px !important;
}
.tippy {
    margin: -5px -9px;
}
.img-40 {
    width: 40px;
    height: 40px;
    object-fit: contain;
}
