.artist-create .content {
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 1fr;
}

.artist-create .breadcrumb {
    grid-area: 1 / 1 / 2 / 2;
}

.artist-create .create-form {
    grid-area: 2 / 1 / 3 / 2;
}

.artist-create .create-result {
    grid-area: 3 / 1 / 4 / 2;
}
