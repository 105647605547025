.artist-view-recording-charts-data .img-40 {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.artist-view-recording-charts-data .expanding-charts .row-expansion-style {
    padding: 0;
    background-color: #e9ecef;
}

.artist-view-recording-charts-data
    .expanding-charts
    .row-expansion-style:hover {
    background-color: #e9ecef;
}
